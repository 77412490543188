import { render, staticRenderFns } from "./SubscriptionPricing.vue?vue&type=template&id=1245815c&scoped=true&"
import script from "./SubscriptionPricing.vue?vue&type=script&lang=ts&"
export * from "./SubscriptionPricing.vue?vue&type=script&lang=ts&"
import style0 from "./SubscriptionPricing.vue?vue&type=style&index=0&id=1245815c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1245815c",
  null
  
)

export default component.exports