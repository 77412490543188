import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"subscription-pricing"}},[_c(VContainer,{staticStyle:{"max-width":"1600px"}},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"text-h4 font-weight-bold mb-3"},[_vm._v(" "+_vm._s(_vm.$t("subscription.pricing.title"))+" ")]),_c('div',{staticClass:"text-body-2 mb-5"},[_vm._v(" "+_vm._s(_vm.$t("subscription.pricing.description"))+" ")])]),_c('div',{staticClass:"switch-container"},[_c(VSwitch,{staticClass:"mr-3 mb-4",attrs:{"label":!_vm.isYearly
            ? _vm.$t('subscription.monthlyPayment')
            : _vm.$t('subscription.yearlyPayment')},model:{value:(_vm.isYearly),callback:function ($$v) {_vm.isYearly=$$v},expression:"isYearly"}})],1),(_vm.getting)?_c('div',{staticClass:"d-flex justify-center align-center flex-column"},[_c('div',{staticClass:"mb-4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("subscription.pricing.fetching"))+" ")]),_c(VProgressCircular,{attrs:{"size":"64","indeterminate":""}})],1):_c(VRow,{staticClass:"d-flex justify-center align-stretch"},_vm._l((_vm.active),function(item,i){return _c('PricingTier',{key:i,attrs:{"item":item,"i":i,"is-yearly-prop":_vm.isYearly,"price":!_vm.isYearly ? item.unitAmountMonthly : item.unitAmountYearly},on:{"update:isYearlyProp":function($event){_vm.isYearly=$event},"update:is-yearly-prop":function($event){_vm.isYearly=$event}}})}),1),_c('div',{staticClass:"text-center mt-16"},[_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("subscription.pricing.orContinueFree"))+" ")]),_c(VBtn,{staticClass:"grey darken-2 my-3 mt-12",staticStyle:{"color":"white","width":"320px"},attrs:{"to":"/dashboard"}},[_vm._v(" "+_vm._s(_vm.$t("subscription.pricing.continueFree"))+" ")]),_c('div',{staticClass:"text-caption mb-5"},[_vm._v(" "+_vm._s(_vm.$t("subscription.pricing.compare"))+" "),_c(VIcon,{staticClass:"text-caption black--text"},[_vm._v("mdi-arrow-top-right")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }